<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">CHEQUE BOOK MONITORING (CANCELED)</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-layout row wrap class="align-center mx-2 mt-5">
        <v-flex xs12 md12>
          <v-form ref="form" class="multi-col-validation">
            <v-layout row wrap class="mx-1 mb-5">
              <v-flex xs12 md12>
                <v-select
                  v-model="option"
                  class="mx-2  mt-2"
                  dense
                  outlined
                  label="Option"
                  :items="['Monthly','Search Cheque']"
                  @change="selected_category"
                  :rules="rules.combobox_rule"
                ></v-select>
                <v-autocomplete
                  v-if="option==='Search Cheque'"
                  dense
                  outlined
                  v-model="search"
                  :items="search_items"
                  :loading="isLoading"
                  @keyup.enter="searching($event.target.value)"
                  hide-no-data
                  hide-selected
                  item-text="cheque_no"
                  item-value="id"
                  label="Search"
                  class="mx-2"
                  placeholder="Press Enter to Search "
                  :prepend-icon="icons.mdiAccountSearchOutline"
                  @change="get_search_items_info"
                ></v-autocomplete>
                <v-select
                  v-if="option==='Monthly'"
                  v-model="month_of_id"
                  class="mx-2"
                  dense
                  outlined
                  label="Month Of"
                  :items="month_of_items"
                  item-value="id"
                  item-text="month_of"
                  @change="selected_month"
                  :rules="rules.combobox_rule"
                ></v-select>
              </v-flex>
            </v-layout>
          </v-form>
        </v-flex>
        <v-flex xs12 md12 class="mx-2">
          <h2 v-if="!is_deleting_request && data_items.length > 0">
            Print |
            <v-icon
              color="success"
              @click="print_data()"
            >
              {{ icons.mdiPrinter }}
            </v-icon>
            <v-progress-circular
              color="info"
              indeterminate
              v-if="is_deleting_request"
            ></v-progress-circular>
          </h2>
          <v-data-table dense
                        :headers="headers"
                        :items="data_items"
          >
            <template v-slot:item="{ item }">
              <tr>
                <td>
                  {{ item.bank.bank_code }}
                </td>
                <td>
                  {{ item.cheque_no }}
                </td>
                <td>
                  {{ item.reason }}
                </td>
                <td>
                  {{ item.date }}
                </td>
                <td>
                  {{
                  (item.ckv!=null?'CKV#'+item.ckv.voucher_no:(item.cv!=null?'CV#'+item.cv.voucher_no:(item.ftv!=null?'FTV#'+item.ftv.voucher_no:'')))
                  }}
                </td>
                <td>
                  {{ item.transaction_month.month_of }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>
    </v-card>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {
    mdiAccountOutline,
    mdiAccountCashOutline,
    mdiInformationOutline,
    mdiPrinter,
    mdiGoogleCirclesCommunities, mdiCharity, mdiGift, mdiHandshakeOutline
  } from "@mdi/js";
  import moment from "moment";
  import {mapActions, mapGetters} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
  import category from "@/store/module/data/category";

  const initialState = () => {
    return {
      headers: [
        {text: 'BANK CODE', value: 'date_of_deposit', sortable: false},
        {text: 'CHEQUE #', value: 'date_of_deposit', sortable: false},
        {text: 'REASON', value: 'date_of_deposit', sortable: false},
        {text: 'DATE CANCELED', value: 'date_of_deposit', sortable: false},
        {text: 'VOUCHER#', value: 'date_of_deposit', sortable: false},
        {text: 'MONTH OF', value: 'date_of_deposit', sortable: false},
      ],
      is_deleting_request: false,
      isLoading: false,
      search: '',
      search_items: [],
      data_items: [],
      month_of_items: [],
      month_of_id: '',
      option: '',
    }
  }
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiAccountOutline,
          mdiAccountCashOutline,
          mdiInformationOutline,
          mdiPrinter,
        },
      }
    },
    data() {
      return initialState()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['employee_id', 'month_of']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
      ...mapGetters('authentication', [
        'employee_id',
        'address',
        'contact',
        'name',
        'department',
        'position',
        'employee_category_id',
        'employee_branch_data',
        'employee_branch_id',
      ]),
      ...mapGetters('system_data', [
        'snackbar',
        'snackbar_color',
        'snackbar_text',
        'company_logo',
        'damayan',
        'chapel',
        'factory',
        'coop',
        'printing',
        'accounting',
        'admin',
        'amd',
        'audit',
        'book',
        'creative',
        'ecut',
        'hr',
        'it',
        'prot',
        'trea',
      ]),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('cheque_book_series_canceled', ['list_of_canceled_cheque_monthly', 'search_cheque_no_canceled']),
      searching(value) {
        this.search_cheque_no_canceled({
          cheque_no: value,
        })
          .then(response => {
            this.search_items = response.data
            this.isLoading = false
          })
      },
      get_search_items_info(value) {
        if (this.search_items.length > 0) {
          var index = this.search_items.map(function (x) {
            return x.id;
          }).indexOf(value)
          if (index != -1) {
            this.data_items = [this.search_items[index]]
          } else {
            this.data_items = []
          }
        } else {
          this.data_items = []
        }
      },
      selected_month(value) {
        this.data_items = []
        var index = this.month_of_items.map(function (x) {
          return x.id;
        }).indexOf(value)
        if (index != -1) {
          this.data_items = this.month_of_items[index].cheque_series_canceled
        }
      },
      selected_category(value) {
        this.data_items = []
        this.month_of_id = ''
        if (value === 'Monthly') {
          this.list_of_canceled_cheque_monthly()
            .then(response => {
              this.month_of_items = response.data
            })
        }
      },
      async print_data() {
        this.is_deleting_request = true
        var imgData = this.company_logo

        var payments_array = []
        var widths = []
        var info_array = []
        var cheque_array = {}

        var damayan_ = this.damayan
        var chapel_ = this.chapel
        var factory_ = this.factory
        var coop_ = this.coop
        var printing_ = this.printing

        widths = [120, 50, 125, 60, 60, 60]
        payments_array.push([
          {text: 'Bank Code', alignment: 'left', style: 'main_info'},
          {text: 'Cheque #', alignment: 'left', style: 'main_info'},
          {text: 'Reason', alignment: 'left', style: 'main_info'},
          {text: 'Date Canceled', alignment: 'left', style: 'main_info'},
          {text: 'Voucher #', alignment: 'left', style: 'main_info'},
          {text: 'Month Of', alignment: 'left', style: 'main_info'},
        ])

        this.data_items.forEach(function (item, index, payment) {
          payments_array.push([
            {
              text: item.bank.bank_code,
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: item.cheque_no,
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: item.reason,
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: item.date,
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: (item.ckv!=null?'CKV#'+item.ckv.voucher_no:(item.cv!=null?'CV#'+item.cv.voucher_no:(item.ftv!=null?'FTV#'+item.ftv.voucher_no:''))),
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: item.transaction_month.month_of,
              alignment: 'left',
              style: 'tableExample2',
            },
          ])
        })

        info_array = [
          {
            columns: [
              {
                style: 'main_info',
                text: [
                  'MONTH OF: ',
                  {
                    text: this.month_of_items[
                      this.month_of_items
                        .map(function (x) {
                          return x.id
                        })
                        .indexOf(this.month_of_id)
                      ].month_of,
                    style: 'main_data',
                  },
                ],
              },
            ],
          },
        ]

        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs
        }
        var docDefinition = {
          pageSize: 'LETTER',
          pageOrientation: 'portrait',
          content: [
            {
              columns: [
                {image: imgData, width: 60, height: 54, style: 'logo'},
                {
                  stack: [
                    'GOODLIFE GROUP OF COMPANIES',
                    {text: this.address, style: 'subheader2'},
                    {
                      text: this.contact,
                      style: 'subheader2',
                    },
                    {
                      text: 'Printed as of: ' + moment().format('MMMM D, YYYY'),
                      style: 'printed_label',
                    },
                  ],
                  style: 'header',
                },
                {
                  image:
                    this.position === 'ADMIN'
                      ? this.admin
                      : this.position === 'TREASURY'
                      ? this.trea
                      : this.position === 'ACCOUNTING'
                        ? this.accounting
                        : this.position === 'AMD'
                          ? this.amd
                          : this.position === 'PROPERTY CUSTODIAN'
                            ? this.prot
                            : this.position === 'HUMAN RESOURCE'
                              ? this.hr
                              : this.position === 'BOOKKEEPING'
                                ? this.book
                                : this.position === 'CMD'
                                  ? this.accounting
                                  : imgData,
                  width: 60,
                  height: 54,
                  style: 'logo',
                },
              ],
            },
            {
              stack: [
                {
                  columns: [
                    {
                      text: 'CHEQUE BOOK MONITORING (CANCELED)',
                      style: {
                        fontSize: 16,
                        alignment: 'center',
                        bold: true,
                      },
                    },
                  ],
                },
              ],
            },
            {
              columns: [{text: ' '}],
            },
            {
              columns: [{text: ' '}],
            },
            {
              stack: info_array,
            },
            {
              columns: [{text: ' '}],
            },
            {
              table: {
                widths: widths,
                body: payments_array,
                style: {fontSize: 1},
              },
              layout: {
                fillColor: function (rowIndex) {
                  return rowIndex === 0 ? '#CCCCCC' : null
                },
              },
            },
            {
              stack: [
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [
                    {
                      text: 'PROCESSED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: 'DEPARTMENT RECEIVER',
                      style: 'main_data_2',
                    },
                    {
                      text: 'RECEIVED BY',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: this.name,
                      style: 'main_data_2',
                    },
                    {
                      text: ' ',
                    },
                    {
                      text: '',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '_____________________',
                    },
                    {
                      text: '_____________________',
                    },
                    {
                      text: '_____________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: this.position,
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name',
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '',
                    },
                    {
                      text: ' ',
                    },
                    {
                      text: ' ',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'VERIFIED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: 'AUDITED BY',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '_________________________________________',
                    },
                    {
                      text: '_________________________________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'BOARD OF DIRECTOR',
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name (TREASURY)',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: ' ',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'APPROVED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: '_________________________________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: 'Signature Over Printed Name (ACCOUNTING)',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: ' ',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'ALBERT M. HILARIO',
                      style: 'main_data_2',
                    },
                    {
                      text: '_________________________________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'EVP HEAD FINANCE',
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name (EXECUTIVE)',
                      style: 'main_data_2',
                    },
                  ],
                },
              ],
            },

            cheque_array,
          ],
          footer: function (currentPage, pageCount) {
            return {
              margin: 10,
              columns: [
                {
                  text: 'Page ' + currentPage.toString() + ' of ' + pageCount,
                  fontSize: 9,
                  alignment: 'left',
                },
                {image: damayan_, width: 34, height: 20, style: 'logo'},
                {image: chapel_, width: 34, height: 20, style: 'logo'},
                {image: factory_, width: 34, height: 20, style: 'logo'},
                {image: coop_, width: 34, height: 20, style: 'logo'},
                {image: printing_, width: 34, height: 20, style: 'logo'},
                {
                  text: '',
                },
              ],
            }
          },
          styles: {
            tableExample: {
              fontSize: 9,
            },
            tableExample2: {
              fontSize: 7,
            },
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 5], //[left, top, right, bottom]
            },
            subheader: {
              fontSize: 11,
            },
            subheader2: {
              fontSize: 10,
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0], //[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 0, 0, 0], //[left, top, right, bottom]
            },
            main_data: {
              margin: [0, 2, 0, 2],
              fontSize: 9,
              bold: true,
              alignment: 'left',
            },
            main_data_2: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
              bold: true,
              alignment: 'left',
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
              alignment: 'left',
            },
          },
        }
        pdfMake.createPdf(docDefinition).open()
        this.is_deleting_request = false
      },
    },
  }
</script>
